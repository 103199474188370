import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { Icon } from "@iconify/react";

import * as S from "./styles";

/**
 * @description Avatar component
 * @param {string} src
 * @param {string} size - Tamanho em px / rem / em
 * @param {boolean} favorite
 * @param {function} onClick
 * @param {boolean} enableHoverEffect - Habilita ou desabilita o efeito de hover com ação para ver perfil
 */
export const Avatar = ({
  src,
  size,
  favorite,
  onClick,
  enableHoverEffect = true,
}) => {
  const { t } = useTranslation("common");
  const theme = useTheme();

  return (
    <S.Container
      size={size}
      action={t("seeProfile")}
      onClick={onClick}
      enableHoverEffect={enableHoverEffect}
      clickable={!!onClick}
    >
      <S.Image src={src} size={size} />
      {!!favorite && (
        <S.Heart size={size}>
          <Icon
            icon="fluent:heart-24-filled"
            color={theme.colors.danger_3}
            width={16}
            height={16}
          />
        </S.Heart>
      )}
    </S.Container>
  );
};
