import styled from "styled-components";
import PropTypes from "prop-types";

export const Badge = styled.span`
  border-width: ${({ border }) => (border ? "1px" : "0px")};
  border-style: ${({ border }) => (border ? "solid" : "none")};
  border-color: ${({ color, borderColor, theme }) =>
    borderColor ?? color ?? theme.colors.black_0};

  color: ${({ color, borderColor, theme }) =>
    borderColor ?? color ?? theme.colors.black_0};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? "transparent"};
  border-radius: 2em;
  padding: 0rem ${({ xAxisSpacing }) => (xAxisSpacing ? "0.375rem" : "0rem")};
  white-space: nowrap;

  font-family: ${({ bold }) => (bold ? "Muli Bold" : "Muli")}, sans-serif;
  line-height: clamp(18px, 1.125rem, 20px);
  font-size: clamp(12px, 0.75rem, 16px);

  display: inline-flex;
  align-items: center;
  gap: 0.25rem;

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ clickable, disabled }) =>
    clickable ? (disabled ? "not-allowed" : "pointer") : "default"};
`;

Badge.propTypes = {
  border: PropTypes.bool,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  xAxisSpacing: PropTypes.bool,
  bold: PropTypes.bool,
  clickable: PropTypes.bool,
  disabled: PropTypes.bool,
};

Badge.defaultProps = {
  border: true,
  color: null,
  backgroundColor: null,
  borderColor: null,
  xAxisSpacing: true,
  bold: false,
  disabled: false,
  clickable: false,
};
