import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Text } from "libs/purple-ui";

import defaultImage from "assets/imgs/bad-users-access.svg";
import * as S from "./styles";

export function Message({ src, title }) {
  const { t } = useTranslation(["common"]);

  return (
    <S.Container>
      <S.Image src={src || defaultImage} />
      <Text textAlign="center">{title || t("noResultsFound")}</Text>
    </S.Container>
  );
}

Message.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
};
