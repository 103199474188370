import styled, { css } from "styled-components";
import { Animation } from "libs/purple-ui";

export const Container = styled.div`
  ${({ inProp, enabled, timeout }) => {
    if (!enabled) {
      return css`
        animation: none;
      `;
    }

    return inProp
      ? Animation.GrowInForwardsAnimation({ timeout })
      : Animation.GrowOutForwardsAnimation({ timeout });
  }};

  display: inline;
`;
