import styled from "styled-components";

export const Container = styled.ul`
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  row-gap: 0.25rem;
`;
