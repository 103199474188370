const INITIAL_STATE = {
  user: "establishment",
  pk: null,
};

// TYPES
export const Types = {
  SET_USER: "set-current-user",
};

// REDUCER
export function currentUser(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_USER:
      return {
        ...state,
        user: action.payload.user,
        pk: action.payload.pk,
      };
    default:
      return state;
  }
}

// ACTIONS
export const Creators = {
  setUser: ({ user, pk }) => ({
    type: Types.SET_USER,
    payload: { user, pk },
  }),
};
