import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Container } from "./styles";

/**
 * @param {Node} children - Elemento que será animado
 * @param {boolean} in - Define se a animação deve ser executada na entrada ou saída
 * @param {number} timeout - Tempo da animação
 * @param {boolean} enabled - Define se a animação deve ser executada
 */
export const Grow = ({
  children,
  in: inProp = true,
  timeout = 250,
  enabled = true,
  ...rest
}) => {
  const [show, setShow] = useState(inProp);

  useEffect(() => {
    if (inProp) {
      setShow(true);
    } else {
      const timeoutId = setTimeout(() => setShow(false), timeout);
      return () => clearTimeout(timeoutId);
    }
  }, [inProp, timeout]);

  return (
    show && (
      <Container {...rest} inProp={inProp} timeout={timeout} enabled={enabled}>
        {children}
      </Container>
    )
  );
};

Grow.propTypes = {
  children: PropTypes.node.isRequired,
  in: PropTypes.bool,
  timeout: PropTypes.number,
  enabled: PropTypes.bool,
};
