import PropTypes from "prop-types";

import * as S from "./styles";

/**
 * @description Componente raiz onde o cardlist é renderizado
 * @param {boolean} resetAppearance - Reseta a aparência do cardlist (remove box-shadow, background, padding e radius)
 */
export function Root({ children, resetAppearance }) {
  return (
    <S.Container resetAppearance={resetAppearance}>{children}</S.Container>
  );
}

Root.defaultProps = {
  resetAppearance: false,
};

Root.propTypes = {
  resetAppearance: PropTypes.bool,
};
