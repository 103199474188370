import styled, { css } from "styled-components";
import DefaultAvatar from "assets/imgs/user.jpg";

export const Container = styled.div`
  width: ${({ size }) => size};
  height: ${({ size }) => size};

  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.grey_0};
  cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
  position: relative;

  ${({ enableHoverEffect }) =>
    !!enableHoverEffect &&
    css`
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.65);

        opacity: 0;

        z-index: 5;
        pointer-events: none;
        user-select: none;
        transition: all 0.2s ease;
      }

      &::before {
        content: attr(action);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        font-size: ${({ theme }) => theme.font.size.xxs};
        line-height: ${({ theme }) => theme.font.lineHeight.small};
        font-family: ${({ theme }) => theme.font.family.regular};
        color: ${({ theme }) => theme.colors.white_pure};

        opacity: 0;

        z-index: 10;
        pointer-events: none;
        user-select: none;
        transition: all 0.15s ease-out;
      }

      &:hover::after,
      &:hover::before {
        opacity: 1;
      }
    `}
`;

export const Heart = styled.div`
  width: clamp(20px, 1.25rem, 1.5rem);
  height: clamp(20px, 1.25rem, 1.5rem);

  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white_pure};

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: -4px;
  right: 4px;
  z-index: 20;
`;

export const Image = styled.img.attrs(({ src }) => ({
  src: src || DefaultAvatar,
}))`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;

  user-select: none;
  pointer-events: none;
`;
