import React from "react";
import { useTheme } from "styled-components";
import PropTypes from "prop-types";

import * as S from "./styles";

/**
 *
 * @param {string} size - Tamanho em px ou rem
 * @param {string[string, string]} color - Array com duas cores: (1) Cor de fundo e (2) Cor do destaque
 *
 * @example
 * <Spinner size="1rem" color={[grey_1, grey_4]} />
 */
export const Spinner = ({ size, color = [], ...rest }) => {
  const theme = useTheme();

  if (!color?.length) {
    color = [theme.colors.primary_1, theme.colors.primary_6];
  }

  return (
    <S.Svg
      viewBox="0 0 20 20"
      fill="none"
      {...rest}
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1258_2240)">
        <path
          d="M16.587 3.86723C18.2136 5.61423 19.0795 7.9358 18.9943 10.3212C18.9091 12.7067 17.8798 14.9605 16.1328 16.587C14.3858 18.2136 12.0642 19.0795 9.67878 18.9943C7.29335 18.9091 5.03947 17.8798 3.41295 16.1328C1.78644 14.3858 0.920541 12.0642 1.00573 9.67878C1.09093 7.29335 2.12024 5.03947 3.86723 3.41295C5.61423 1.78644 7.93579 0.920541 10.3212 1.00573C12.7066 1.09093 14.9605 2.12024 16.587 3.86723L16.587 3.86723Z"
          stroke={color[0]}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 1C11.1819 1 12.3522 1.23279 13.4441 1.68508C14.5361 2.13738 15.5282 2.80031 16.364 3.63604C17.1997 4.47177 17.8626 5.46392 18.3149 6.55585C18.7672 7.64778 19 8.8181 19 10"
          stroke={color[1]}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1258_2240">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </S.Svg>
  );
};

Spinner.propTypes = {
  size: PropTypes.string,
  color: PropTypes.arrayOf(PropTypes.string),
};

Spinner.defaultProps = {
  size: "1.25rem",
};
