import styled from "styled-components";
import { Text as PUIText } from "libs/purple-ui";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContainerRadioButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RadioButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: ${({ theme, disabled, checked }) =>
    disabled && checked
      ? theme.colors.grey_2
      : disabled
      ? theme.colors.grey_1
      : theme.colors.white_pure};
  border-color: ${({ theme, checked, disabled }) =>
    disabled
      ? theme.colors.grey_1
      : checked
      ? theme.colors.primary_5
      : theme.colors.grey_2};
  border-width: ${({ checked }) => (checked ? "4px" : "1px")};
  border-style: solid;
  border-radius: ${({ theme }) => theme.borderRadius.rounded};
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

export const ContainerDescription = styled.div`
  padding-left: 1.438rem;
`;

export const Text = styled(PUIText)`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  padding-left: 0.5rem;
  user-select: none;
`;
