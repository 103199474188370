import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.125rem;
  width: 100%;
  height: ${({ fullHeight }) => (fullHeight ? "100%" : "auto")};
  padding: ${({ resetAppearance }) => (resetAppearance ? "0rem" : "1.5rem")};
  border-radius: ${({ radius, resetAppearance }) =>
    resetAppearance ? "0rem" : radius ? radius : "1rem"};
  box-shadow: ${({ theme, resetAppearance }) =>
    resetAppearance
      ? "none"
      : `0rem 0.125rem 0.375rem ${theme.colors.opacity(
          theme.colors.black_pure,
          10
        )}`};
  background: ${({ theme, resetAppearance }) =>
    resetAppearance ? "transparent" : theme.colors.white_pure};

  .cardlist-row {
    flex: 1;
  }

  .cardlist-row + .cardlist-row {
    padding-top: 1rem;
    border-top: 0.063rem solid ${({ theme }) => theme.colors.grey_1};
  }
`;
