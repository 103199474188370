import { css, keyframes } from "styled-components";

export const rotateKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);
  }
`;

const waveAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const growInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const growOutAnimation = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
`;

export const InfiniteRotateAnimation = css`
  animation: ${rotateKeyframes} 0.75s linear infinite;
`;

export const InfiniteWaveAnimation = css`
  animation: ${waveAnimation} 1.5s infinite linear;
`;

export const GrowInForwardsAnimation = ({ timeout }) => css`
  animation: ${growInAnimation} ${timeout}ms ease-in-out forwards;
`;

export const GrowOutForwardsAnimation = ({ timeout }) => css`
  animation: ${growOutAnimation} ${timeout}ms ease-in-out forwards;
`;
