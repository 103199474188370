import * as S from "./styles";

/**
 * @description Cabeçalho do CardList
 * @param {node} title - Componente de título do CardList
 * @param {node} subtitle - Componente de subtítulo do CardList
 * @param {node} action - Componente de menu do CardList
 * @param {string} align - Alinhamento do cabeçalho
 */
export function Header({ title, subtitle, action, align }) {
  return (
    <S.Container align={align}>
      <S.Header>
        {title}
        {subtitle}
      </S.Header>
      {action}
    </S.Container>
  );
}
