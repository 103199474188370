import PropTypes from "prop-types";

import { Grow } from "libs/purple-ui";

import * as S from "./styles";

/**
 * @description Componente de linha para CardList
 * @param {Node} children
 * @param {number?} index - Envie o index para ativar a animação de crescimento. Se omitido a animação não será executada
 * @param {Node?} controls - Controles para linha (checkbox para ação em lote ou qualquer outro componente para ser renderizado à esquerda da linha)
 */
export function Row({ children, index, controls }) {
  const withGrow = typeof index === "number";

  return (
    <Grow
      className="cardlist-row"
      enabled={withGrow}
      {...(withGrow && {
        in: true,
        timeout: index * 50 + 250,
      })}
    >
      <S.Wrapper controls={!!controls}>
        {!!controls && <S.ControlsColumn>{controls}</S.ControlsColumn>}
        <S.Container>{children}</S.Container>
      </S.Wrapper>
    </Grow>
  );
}

Row.propTypes = {
  controls: PropTypes.node,
  index: PropTypes.number,
};
