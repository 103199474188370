import mainService from "services/mainService";

/**
 * --------------------------------------------------
 * Retorna a lista de funções customizadas
 * @param {object} params
 * @param {string} params.userPk - Id do usuário
 * @param {string} params.pageSize - Tamanho da página
 * @param {string} params.pageIn - Página
 * @param {string} params.name - Nome da função customizada
 *
 * @example
 * getCustomRoles({
 *  userPk: 1234,
 *  pageSize: 10,
 *  pageIn: 1,
 *  name: 'Operador'
 * })
 */
export const getCustomRoles = ({ userPk, pageSize, pageIn, name }) => {
  return mainService.get(
    `v2/bigcompany/${userPk}/customized_roles?page_size=${pageSize}&page=${pageIn}&list_all=false&name=${name}`
  );
};

/**
 * --------------------------------------------------
 * Cria uma função customizada
 * @param {object} params
 * @param {string} params.userPk - Id do usuário
 * @param {object} params.body - Dados da função customizada
 *
 * @example
 * createCustomRole({userPk: 1234, body: { name: 'Operador' } })
 */
export const createCustomRole = ({ userPk, body }) => {
  return mainService.post(`v2/bigcompany/${userPk}/customized_roles`, body);
};

/**
 * --------------------------------------------------
 * Edita uma função customizada
 * @param {object} params
 * @param {string} params.userPk - Id do usuário
 * @param {string} params.pk - Id da função customizada
 * @param {object} params.body - Dados da função customizada
 *
 * @example
 * editCustomRole({userPk: 1234, pk: 1234, body: { name: 'Operador' } })
 */
export const editCustomRole = ({ userPk, pk, body }) => {
  return mainService.patch(
    `v2/bigcompany/${userPk}/customized_roles/${pk}`,
    body
  );
};

/**
 * --------------------------------------------------
 * Deleta uma função customizada
 * @param {object} params
 * @param {string} params.userPk - Id do usuário
 * @param {string} params.pk - Id da função customizada
 *
 * @example
 * deleteCustomRole({userPk: 1234, pk: 1234 })
 */
export const deleteCustomRole = ({ userPk, pk }) => {
  return mainService.delete(`v2/bigcompany/${userPk}/customized_roles/${pk}`);
};

/**
 * --------------------------------------------------
 * Ativa ou desativa a listagem de somente as funções customizadas na endpoint de listagem de funções
 * @param {object} params
 * @param {string} params.userPk - Id do usuário
 *
 * @example
 * toggleListAllCustomRoles({ userPk: 1234 })
 */
export const toggleListAllCustomRoles = ({ userPk }) => {
  return mainService.post(
    `v2/bigcompany/${userPk}/customized_roles/toggle_for_job_creation`
  );
};

/**
 * --------------------------------------------------
 * Relaciona um profissional a uma função customizada
 * @param {number} bigPk - Id da bigcompany
 * @param {number} rolePk - Id da função custom
 * @param {number} workerPk - Id do worker
 *
 * @example
 * customRoleToWorker({ bigPk: 1234, rolePk: 12, workerPk: 21 })
 */
export const customRoleToWorker = ({ bigPk, rolePk, workerPk }) => {
  return mainService.post(
    `v2/bigcompany/${bigPk}/customized_roles/${rolePk}/worker/${workerPk}`
  );
};
