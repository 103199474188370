import { Root } from "./root";
import { Header } from "./header";
import { Row } from "./row";
import { Body } from "./body";
import { Title } from "./title";
import { Subtitle } from "./subtitle";
import { Badges } from "./badges";
import { Footer } from "./footer";
import { Message } from "./message";
import { Grid } from "./grid";

export const CardList = {
  Root,
  Header,
  Row,
  Body,
  Title,
  Subtitle,
  Badges,
  Footer,
  Message,
  Grid,
};
