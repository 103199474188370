import styled, { css } from "styled-components";
import PropTypes from "prop-types";

const getFontWeight = ({ variant, theme }) => {
  switch (variant) {
    case "bold":
      return theme.font.family.bold;
    case "semibold":
      return theme.font.family.semibold;
    case "regular":
    default:
      return theme.font.family.regular;
  }
};

/* Base element to Headings and Paragraph */
const TextElement = styled.p`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-word;

  text-decoration: ${({ decoration }) => {
    const decorations = {
      underline: "underline",
      line: "line-through",
      none: "none",
    };

    return decorations[decoration] || "none";
  }};
  color: ${({ theme, color }) => color || theme.colors.black_0};
`;

/* Base element with work sans font family */
const BaseHeading = styled(TextElement)`
  font-family: ${({ theme, variant }) => getFontWeight({ variant, theme })};
  text-transform: ${({ textTransform }) => textTransform || "none"};
  text-align: ${({ textAlign }) => textAlign || "left"};
  font-weight: normal;
`;

/**
 * Heading Component
 * @param {string} size - h1, h2, h3, h4, h5, h6
 * @param {string} variant - regular, semibold, bold
 * @param {string} color - color name from theme
 * @param {string} decoration - none, underline, line
 * @param {string} textAlign - 'auto', 'left', 'right', 'center', 'justify'
 * @param {string} textTransform - none | capitalize | uppercase | lowercase | full-width | full-size-kana
 * @param {string} children - text
 * @example
 * <Heading
 *  size="h2"
 *  variant="semibold"
 *  decoration="underline"
 *  color="red"
 *  textAlign="center"
 *  textTransform="lowercase"
 * >
 *  Text here
 * </Heading>
 */
export const Heading = styled(BaseHeading)`
  ${({ theme, size }) => {
    const sizes = {
      h1: {
        fontSize: theme.font.size.xxl,
        lineHeight: theme.font.lineHeight.large,
        letterSpacing: theme.font.letterSpacing.xxs,
      },
      h2: {
        fontSize: theme.font.size.xl,
        lineHeight: theme.font.lineHeight.medium,
        letterSpacing: theme.font.letterSpacing.xxs,
      },
      h3: {
        fontSize: theme.font.size.l,
        lineHeight: theme.font.lineHeight.medium,
        letterSpacing: theme.font.letterSpacing.xxs,
      },
      h4: {
        fontSize: theme.font.size.mt,
        lineHeight: theme.font.lineHeight.medium,
        letterSpacing: theme.font.letterSpacing.xxs,
      },
      h5: {
        fontSize: theme.font.size.m,
        lineHeight: theme.font.lineHeight.regular,
        letterSpacing: theme.font.letterSpacing.xxs,
      },
      h6: {
        fontSize: theme.font.size.ms,
        lineHeight: theme.font.lineHeight.small,
        letterSpacing: theme.font.letterSpacing.xxs,
      },
    };

    const _heading = sizes[size] || sizes.h1;

    return css`
      font-size: ${_heading.fontSize};
      line-height: ${_heading.lineHeight};
      letter-spacing: ${_heading.letterSpacing};
    `;
  }};
`;

Heading.defaultProps = {
  variant: "bold",
};

Heading.propTypes = {
  variant: PropTypes.oneOf(["regular", "semibold", "bold"]),
  size: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
  decoration: PropTypes.oneOf(["none", "underline", "line"]),
  textAlign: PropTypes.oneOf(["auto", "left", "right", "center", "justify"]),
  textTransform: PropTypes.oneOf([
    "none",
    "capitalize",
    "uppercase",
    "lowercase",
    "full-width",
    "full-size-kana",
  ]),
};

/* =================================================== */
/* =================================================== */
/* =================================================== */
/* Base element with inter font family */
const BaseText = styled(TextElement)`
  font-family: ${({ theme, variant }) => getFontWeight({ variant, theme })};
  text-transform: ${({ textTransform }) => textTransform || "none"};
  text-align: ${({ textAlign }) => textAlign || "left"};
  cursor: ${({ pointer, disabled }) =>
    pointer ? "pointer" : disabled ? "default" : "default"};
  color: ${({ theme, color, disabled }) =>
    disabled ? theme.colors.grey_1 : color ? color : theme.colors.black_0};
`;

/**
 * Text Component
 * @param {string} size - extra_small, small, regular, large
 * @param {string} variant - regular, semibold, bold
 * @param {string} color - color name from theme
 * @param {string} decoration - none, underline, line
 * @param {string} textAlign - 'auto', 'left', 'right', 'center', 'justify'
 * @param {string} textTransform - none | capitalize | uppercase | lowercase | full-width | full-size-kana
 * @param {string} children - text
 * @param {boolean} disabled - deixa o texto desabilitado a clicks e cinza
 * @param {boolean} pointer - define o estado pointer para o texto
 * @example
 * <Text
 *  size="large"
 *  variant="bold"
 *  decoration="line"
 *  color="green"
 *  textAlign="center"
 *  textTransform="uppercase"
 *  disabled=false
 *  pointer=true
 * >
 *  Text here
 * </Text>
 */
export const Text = styled(BaseText)`
  ${({ theme, size }) => {
    const sizes = {
      large: {
        fontSize: theme.font.size.ms,
        lineHeight: theme.font.lineHeight.medium,
      },
      regular: {
        fontSize: theme.font.size.s,
        lineHeight: theme.font.lineHeight.medium,
      },
      small: {
        fontSize: theme.font.size.xs,
        lineHeight: theme.font.lineHeight.regular,
      },
      extra_small: {
        fontSize: theme.font.size.xxs,
        lineHeight: theme.font.lineHeight.small,
      },
    };

    const _text = sizes[size] || sizes.regular;

    return css`
      font-size: ${_text.fontSize};
      line-height: ${_text.lineHeight};
      letter-spacing: ${theme.font.letterSpacing.xs};
    `;
  }};
`;

Text.propTypes = {
  variant: PropTypes.oneOf(["regular", "semibold", "bold"]),
  size: PropTypes.oneOf(["extra_small", "small", "regular", "large"]),
  decoration: PropTypes.oneOf(["none", "underline", "line"]),
  textAlign: PropTypes.oneOf(["auto", "left", "right", "center", "justify"]),
  textTransform: PropTypes.oneOf([
    "none",
    "capitalize",
    "uppercase",
    "lowercase",
    "full-width",
    "full-size-kana",
  ]),
  disabled: PropTypes.bool,
  pointer: PropTypes.bool,
};
