import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 0.875rem;
  grid-template-columns: ${({ row }) => (row ? "repeat(2, 1fr)" : "1fr")};
  padding-top: 0.5rem;
`;

export const Separator = styled.div`
  height: 1rem;
`;
