import { isCPF } from "utils/basic";

const particles_dictionary = [
  "da",
  "das",
  "de",
  "des",
  "di",
  "dis",
  "do",
  "dos",
  "du",
  "dus",
  "e",
];

export const CONFIG_RANGE_SCORE = {
  min: 0,
  max: 100,
  step: 1,
};

export const CONFIG_RANGE_COUNT_JOBS = {
  min: 0,
  max: 1000,
  step: 10,
};

export const PersistedKey = "/dashboard/freelancer/minha-base";

export const QUERY_KEYS_LIST = {
  professionals: "get_professionals_list",
  filters: "get_professionals_list_filters",
};

export const QueryControls = {
  stale: (second = 30) => 1 * second * 1000,
  retry: 3,
};

const SearchValidator = {
  isCPF: (value) => isCPF(value),
  isPhone: (value) => {
    const regex = new RegExp(
      "^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$"
    );
    return !!regex.test(value);
  },
};

export const getParamsRequest = ({
  filters = {},
  url = "",
  objectMode = false,
}) => {
  let obj = {};

  if (!!filters?.search?.trim()) {
    let _search = filters.search?.trim();
    let search_has_only_letters = /[a-zA-Z]/g.test(_search);
    let search_with_only_numbers = _search?.replace(/[^\d]/g, "");

    let isSearchByCPF =
      !search_has_only_letters &&
      SearchValidator.isCPF(search_with_only_numbers);
    let isSearchByPhone =
      !search_has_only_letters &&
      SearchValidator.isPhone(search_with_only_numbers);

    if (isSearchByCPF) {
      url += "&cpf=" + search_with_only_numbers;
      obj.cpf = search_with_only_numbers;
    } else if (isSearchByPhone) {
      // force +55 country code for phone search
      url += "&phone=" + "55" + search_with_only_numbers;
      obj.phone = "55" + search_with_only_numbers;
    } else {
      url += "&search=" + _search;
      obj.search = _search;
    }
  }
  if (!!filters.group_type) {
    url += "&group_type=" + filters.group_type;
    obj.group_type = filters.group_type;
  }
  if (!!filters?.roles?.length) {
    let _roles = filters?.roles?.map((item) => item.value);
    url += "&role=" + _roles.join(",");
    obj.role = _roles;
  }
  if (!!filters.doc) {
    url += "&doc=" + filters.doc;
    obj.doc = filters.doc;
  }

  if (!!filters.ordenation) {
    url += "&ordenation=" + filters.ordenation;
    obj.ordenation = filters.ordenation;
  }

  if (!!filters.status) {
    url += "&status=" + filters.status;
    obj.status = filters.status;
  }

  if (!!filters.state) {
    url += "&state=" + filters.state.value;
    obj.state = filters.state.value;

    if (!!filters.city) {
      url += "&city=" + filters.city.value;
      obj.city = filters.city.value;

      if (!!filters.neighb) {
        url += "&neighb=" + filters.neighb.value;
        obj.neighb = filters.neighb.value;
      }
    }
  }
  if (!!filters?.score && filters?.score?.[1] > CONFIG_RANGE_SCORE?.min) {
    url += `&rating=${filters?.score}`;
    obj.rating = filters?.score;
  }
  if (
    !!filters?.count_jobs &&
    filters?.count_jobs?.[1] > CONFIG_RANGE_COUNT_JOBS?.min
  ) {
    url += `&count_jobs=${filters?.count_jobs}`;
    obj.count_jobs = filters?.count_jobs;
  }
  if (!!filters?.watched_course) {
    url += `&watched_course=${filters?.watched_course}`;
    obj.watched_course = filters?.watched_course;
  }

  if (objectMode) {
    return obj;
  }

  return url;
};

export function getCapitalizeName(name = "") {
  let fullName = [];

  if (typeof name !== "string") {
    return "";
  }

  name
    ?.trim()
    ?.split(" ")
    ?.forEach((item) => {
      let string = particles_dictionary.includes(item.toLowerCase())
        ? item.replace(/(^|\s)\S/g, (l) => l.toLowerCase())
        : item.replace(/(^|\s)\S/g, (l) => l.toUpperCase());
      fullName.push(string);
    });

  return fullName.join(" ");
}

export function validateCPF(cpf) {
  cpf = cpf.replace(/[^\d]+/g, "");
  // Elimina CPFs invalidos conhecidos
  if (
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999"
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(10))) return false;
  return true;
}

export function FormValidator(form, validator) {
  let schema = validator;
  const MAX_LENGTH_PHONE = 13;
  Object.keys(form).forEach((key) => {
    if (key === "email" && !!form[key] && !form[key].match(/\S+@\S+\.\S+/)) {
      schema = {
        ...schema,
        [key]: {
          error: "professional:modal.form_validator.invalid_field",
        },
      };
    } else if (/^([_]{1})/.test(key)) {
      schema = {
        ...schema,
        [key]: {
          error: "",
        },
      };
    } else if ((form[key] === "" || form[key] === null) && key === "name") {
      schema = {
        ...schema,
        [key]: {
          error: "professional:modal.form_validator.required_field",
        },
      };
    } else if (
      (form[key] === "" || form[key] === null) &&
      key === form.typeInvite &&
      key !== "name"
    ) {
      schema = {
        ...schema,
        [key]: {
          error: "professional:modal.form_validator.required_field",
        },
      };
    } else if (
      key === form.typeInvite &&
      key == "phone" &&
      form[key].trim().length < MAX_LENGTH_PHONE
    ) {
      schema = {
        ...schema,
        [key]: {
          error: "professional:modal.form_validator.phone_invalid",
        },
      };
    } else if (
      key === form.typeInvite &&
      key == "cpf" &&
      !validateCPF(form.cpf)
    ) {
      schema = {
        ...schema,
        [key]: {
          error: "professional:modal.form_validator.cpf_invalid",
        },
      };
    } else {
      schema = {
        ...schema,
        [key]: {
          error: "",
        },
      };
    }
  });

  const isInvalid = Object.keys(schema).some((key) => schema[key].error);

  return { schema, isInvalid };
}

export const BASE_TEN = 10; // Constante para base decimal porque o resultado da avaliação da trilha do back-end ela vem entre 0-1
