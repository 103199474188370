import styled from "styled-components";

export const Wrapper = styled.li`
  list-style: none;
  display: grid;
  grid-template-columns: ${({ controls }) => (controls ? "auto 1fr" : "1fr")};
  gap: 1rem;
  align-items: flex-start;
`;

export const Container = styled.li`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
`;

export const ControlsColumn = styled.div`
  flex: none;
`;
