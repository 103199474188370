import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "react-bootstrap";
import { IconButton } from "@material-ui/core";

import { getTypeToast } from "./utils";
import { Creators as NotificationActions } from "store/ducks/notification";

import CloseIcon from "@material-ui/icons/Close";

import "styles/components/notification.scss";
import * as S from "./styles";

/**
 * @param {boolean} show
 * @param {node} content
 * @param {string} show
 *
 * @returns React.Component
 */
const ToastNotification = () => {
  const dispatch = useDispatch();

  const show = useSelector((state) => state.notification.show);
  const content = useSelector((state) => state.notification.content);
  const variant = useSelector((state) => state.notification.variant);

  const status = useMemo(() => getTypeToast(variant), [variant]);

  useEffect(() => {
    return () => dispatch(NotificationActions.hide());
  }, []);

  return (
    <Toast
      onClose={() => dispatch(NotificationActions.hide())}
      show={show}
      delay={10000}
      autohide
      animation
      className="notification"
    >
      {show && (
        <S.Container color={status?.color}>
          {status?.icon}
          <S.Wrapper>
            <S.Text>{content}</S.Text>
            <IconButton
              onClick={() => dispatch(NotificationActions.hide())}
              style={{ width: "1.5rem", height: "1.5rem" }}
            >
              <CloseIcon />
            </IconButton>
          </S.Wrapper>
        </S.Container>
      )}
    </Toast>
  );
};

export default ToastNotification;
