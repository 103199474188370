import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import useDebounce from "hooks/useDebounce";

const ripple = keyframes`
  to {
    transform: scale(4);
    opacity: 0;
  }
`;

const RippleElement = styled.span`
  position: absolute;
  background: ${({ color, theme }) =>
    color === "inherit"
      ? theme.colors.grey_4
      : color || theme.colors.white_pure};
  opacity: 25%;
  transform: scale(0);
  animation: ${ripple} 600ms linear;
  border-radius: 50%;
`;

export const useRipple = ({ ref, color }) => {
  const [ripples, setRipples] = useState([]);

  useEffect(() => {
    if (ref.current) {
      const elem = ref.current;

      const clickHandler = (e) => {
        var rect = elem.getBoundingClientRect();
        var left = e.clientX - rect.left;
        var top = e.clientY - rect.top;
        const height = elem.clientHeight;
        const width = elem.clientWidth;
        const diameter = Math.max(width, height);

        setRipples((previousRipple) => [
          ...previousRipple,
          {
            top: top - diameter / 2,
            left: left - diameter / 2,
            height: Math.max(width, height),
            width: Math.max(width, height),
          },
        ]);
      };

      elem.addEventListener("click", clickHandler);

      return () => {
        elem.removeEventListener("click", clickHandler);
      };
    }
  }, [ref]);

  const [_debounced] = useDebounce(ripples, () => {}, 700);

  useEffect(() => {
    if (_debounced.length) {
      setRipples([]);
    }
  }, [_debounced.length]);

  return ripples?.map((style, i) => {
    return (
      <RippleElement
        color={color}
        key={i}
        style={{
          ...style,
        }}
      />
    );
  });
};
