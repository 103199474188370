import styled from "styled-components";
import { Text as PUIText } from "libs/purple-ui";

export const ContainerCheckbox = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const Checkbox = styled.div`
  flex: none;
  cursor: ${({ disabled, clickable }) =>
    disabled || !clickable ? "default" : "pointer"};
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  background-color: ${({ theme, disabled, checked }) =>
    disabled
      ? theme.colors.grey_1
      : checked
      ? theme.colors.primary_5
      : theme.colors.white_pure};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 0.063rem solid
    ${({ theme, checked, disabled }) =>
      disabled
        ? theme.colors.grey_1
        : checked
        ? theme.colors.primary_5
        : theme.colors.grey_2};

  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    transform 0.3s ease-in-out;
`;

export const ContainerDescription = styled.div`
  padding-left: 1.438rem;
`;

export const CheckboxWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const Text = styled(PUIText)`
  cursor: ${({ disabled, clickable }) =>
    disabled || !clickable ? "default" : "pointer"};
  padding-left: 0.5rem;
  user-select: none;
`;
