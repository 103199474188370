import mainService from "services/mainService";

/**
 * @description Cancela uma solicitação de crédito
 * @param {string} user - Tipo de usuário - bigcompany | establishment
 * @param {number} userPk - Pk do usuário
 * @param {number} requestPk - Pk da requisição de crédito
 * @example deleteCreditRequest({
 *  user: "bigcompany",
 *  userPk: "2",
 *  requestPk: 19,
 * })
 */
export const deleteCreditRequest = ({
  user = "",
  userPk = null,
  requestPk = null,
}) => {
  return mainService.delete(
    `v2/commercial/${user}/${userPk}/request_credits/${requestPk}`
  );
};

/**
 * @description Atualiza a data de vencimento de uma requisição de crédito
 * @param {string} user - Tipo de usuário - bigcompany | establishment
 * @param {number} userPk - Pk do usuário
 * @param {number} requestPk - Pk da requisição de crédito
 * @example extendDepositExpiration({
 *  user: "bigcompany",
 *  userPk: "2",
 *  requestPk: 19,
 * })
 */
export const extendDepositExpiration = ({
  user = "",
  userPk = null,
  requestPk = null,
}) => {
  return mainService.post(
    `v2/commercial/${user}/${userPk}/request_credits/${requestPk}/update_due_date`
  );
};

/**
 * @description Retorna os dados de uma nota de débito
 * @param {string} user - Tipo de usuário - bigcompany | establishment
 * @param {number} userPk - Pk do usuário
 * @param {number} requestPk - Pk da requisição de crédito
 * @example getDebitNote({
 *  user: "bigcompany",
 *  userPk: "2",
 *  requestPk: 19,
 * })
 */
export const getDebitNote = ({
  user = "",
  userPk = null,
  requestPk = null,
}) => {
  return mainService.get(
    `v2/commercial/${user}/${userPk}/request_credits/${requestPk}/debit_notes`
  );
};

/**
 * @description Retorna os dados de uma requisição de crédito específica
 * @param {string} user - Tipo de usuário - bigcompany | establishment
 * @param {number} userPk - Pk do usuário
 * @param {number} requestPk - Pk da requisição de crédito
 * @example getInvoiceView({
 *  user: "bigcompany",
 *  userPk: "2",
 *  requestPk: 19,
 * })
 */
export const getInvoiceView = ({
  user = "",
  userPk = null,
  requestPk = null,
}) => {
  return mainService.get(
    `v2/commercial/${user}/${userPk}/request_credits/${requestPk}`
  );
};

/**
 * @description Retorna as notas fiscais de uma requisição de crédito
 * @param {string} user - Tipo de usuário - bigcompany | establishment
 * @param {number} userPk - Pk do usuário
 * @param {number} requestPk - Pk da requisição de crédito
 * @example getInvoice({
 *  user: "bigcompany",
 *  userPk: "2",
 *  requestPk: 19,
 * })
 */
export const getInvoice = ({ user = "", userPk = null, requestPk = null }) => {
  return mainService.get(
    `v2/commercial/${user}/${userPk}/request_credits/${requestPk}/invoices`
  );
};
