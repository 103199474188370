import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: ${({ align }) => align || "center"};
  width: 100%;
  flex-wrap: wrap;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex-grow: 1;
`;
