import { Fragment } from "react";
import PropTypes from "prop-types";

import * as S from "./styles";

/**
 * @description Lista flexível de badges
 * @param {array} data
 * @param {function} keyExtractor - Função para extrair a chave única do item
 * @param {function} renderItem - Função para renderizar o item
 */
export function Badges({ data, keyExtractor, renderItem, ...rest }) {
  return (
    <S.Container {...rest}>
      {data?.map((item, index) => (
        <Fragment key={keyExtractor(item, index)}>
          {renderItem({ item }, index)}
        </Fragment>
      ))}
    </S.Container>
  );
}

Badges.defaultProps = {
  data: [],
  keyExtractor: (item, index) => String(index),
  renderItem: ({ item }, index) => null,
};

Badges.propTypes = {
  data: PropTypes.array,
  keyExtractor: PropTypes.func,
  renderItem: PropTypes.func,
};
