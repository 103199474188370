import mainService from "services/mainService";

/**
 * Obtém os detalhes de um trabalhador específico.
 * @param {number} workerPk - ID do trabalhador.
 * @param {number} estabPk - ID do estabelecimento.
 */
export const getWorker = ({ workerPk, estabPk }) => {
  const url = `v2/establishment/${estabPk}/worker/${workerPk}`;
  return mainService.get(url);
};

/**
 * Desbloqueia um trabalhador específico.
 * @param {number} workerPk - ID do trabalhador.
 * @param {number} estabPk - ID do estabelecimento.
 */
export const unblockWorker = ({ workerPk, estabPk }) => {
  const url = `v2/establishment/${estabPk}/blacklists/${workerPk}/`;
  return mainService.delete(url);
};

/**
 * Bloqueia um trabalhador específico.
 * @param {number} workerPk - ID do trabalhador.
 * @param {number} estabPk - ID do estabelecimento.
 */
export const blockWorker = ({ workerPk, estabPk }) => {
  const url = `v2/establishment/${estabPk}/blacklists/`;
  const body = { worker: workerPk };
  return mainService.post(url, body);
};

/**
 * Favorita um trabalhador específico.
 * @param {number} workerPk - ID do trabalhador.
 * @param {number} estabPk - ID do estabelecimento.
 */
export const favoriteWorker = ({ workerPk, estabPk }) => {
  const url = `v2/establishment/${estabPk}/favorites/`;
  const body = { worker: workerPk };
  return mainService.post(url, body);
};

/**
 * Remove um trabalhador específico dos favoritos.
 * @param {number} workerPk - ID do trabalhador.
 * @param {number} estabPk - ID do estabelecimento.
 */
export const unfavoriteWorker = ({ workerPk, estabPk }) => {
  const url = `v2/establishment/${estabPk}/favorites/${workerPk}/`;
  return mainService.delete(url);
};

/**
 * Obtém a pontuação de um trabalhador específico.
 * @param {number} workerPk - ID do trabalhador.
 */
export const getWorkerScore = ({ workerPk }) => {
  const url = `v2/establishment/worker/${workerPk}/worker_rating`;
  return mainService.get(url);
};

/**
 * Obtém a última experiência de um trabalhador específico.
 * @param {number} workerPk - ID do trabalhador.
 */
export const getWorkerLastExperience = ({ workerPk }) => {
  const url = `v2/establishment/worker/${workerPk}/last_experiences`;
  return mainService.get(url);
};

/**
 * Obtém as especializações de um trabalhador específico.
 * @param {number} workerPk - ID do trabalhador.
 */
export const getWorkerSpecializations = ({ workerPk }) => {
  const url = `v2/establishment/worker_courses/${workerPk}`;
  return mainService.get(url);
};

/**
 * Verifica se um trabalhador específico tem um emprego.
 * @param {number} workerPk - ID do trabalhador.
 * @param {number} estabPk - ID do estabelecimento.
 */
export const getHasJob = ({ workerPk, estabPk }) => {
  const url = `v2/establishment/${estabPk}/worker/${workerPk}/has_job`;
  return mainService.get(url);
};

/**
 * Obtém as avaliações de um trabalhador específico.
 * @param {number} workerPk - ID do trabalhador.
 * @param {number} [page=1] - Número da página.
 * @param {number} [pageSize=5] - Tamanho da página.
 */
export const getWorkerRatings = ({ workerPk, page = 1, pageSize = 5 }) => {
  const url = `v2/jobs/offer/${workerPk}/ratings_list?page=${page}&page_size=${pageSize}`;
  return mainService.get(url);
};

/**
 * Obtém o feedback das avaliações de um trabalhador específico.
 * @param {number} workerPk - ID do trabalhador.
 */
export const getWorkerRatingsFeedback = ({ workerPk }) => {
  const url = `v2/jobs/offer/${workerPk}/ratings_list_feedback?&page_size=5`;
  return mainService.get(url);
};

/**
 * Obtém o perfil de avaliações de um trabalhador específico.
 * @param {Object} params - Parâmetros para a requisição.
 * @param {number} workerPk - ID do trabalhador.
 * @returns {Promise} - Promessa com o perfil de avaliações do trabalhador.
 */
export const getWorkerRatingsProfile = ({ workerPk }) => {
  const url = `v2/jobs/offer/${workerPk}/worker_ratings_profile`;
  return mainService.get(url);
};
