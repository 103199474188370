import api from "services/mainService";
import { USERS } from "utils/permissions";

/**
 * @description Lista as vagas do recruta de um estabelecimento ou bigcompany
 * @param {string} user (establishment | bigcompany)
 * @param {number} userId
 * @param {number?} pageSize
 * @param {number?} page
 * @param {string?} queryParams
 * @example
 * getVacancies({ user: "establishment", userId: 1, page: 1, pageSize: 10 })
 */
export function getVacancies({
  user = "establishment",
  userId = null,
  page = 1,
  pageSize = 10,
  queryParams = "",
}) {
  let route = user;

  if (user === USERS.establishment) {
    route = "unit";
  }

  const params = queryParams ? `&${queryParams}` : "";

  return api.get(
    `v2/recruta/${route}/${userId}/positions?page_size=${pageSize}&page=${page}${params}`
  );
}

/**
 * @description Lista os tipos de vagas do recruta
 */
export function getVacanciesKinds() {
  return api.get("v2/recruta/kinds?page_size=all");
}

/**
 * @description Encerra uma vaga
 * @param {number} establishmentId - ID do estabelecimento da vaga
 * @param {number} vacancyId - ID da vaga
 */
export function closeVacancy({ establishmentId, vacancyId }) {
  return api.post(`v2/recruta/unit/${establishmentId}/positions/${vacancyId}`);
}

/**
 * @description Exporta o relatório das vagas do recruta
 * @param {string} user - (establishment | bigcompany)
 * @param {number} userId - ID da unidade ou bigcompany
 * @param {string} email - E-mail para envio do relatório
 * @param {string} queryParams - Filtros do relatório
 */
export function exportVacanciesReport({
  user,
  userId,
  email,
  queryParams = "",
}) {
  let route = user;

  if (user === USERS.establishment) {
    route = "unit";
  }

  const params = queryParams ? `?${queryParams}` : "";

  return api.post(`v2/recruta/${route}/${userId}/positions/report/${params}`, {
    email,
  });
}

/**
 * @description Exporta o relatório de candidatos de uma vaga do recruta
 * @param {number} establishmentId - ID do estabelecimento da vaga
 * @param {number} vacancyId - ID da vaga
 * @param {string} email - E-mail para envio do relatório
 */
export function exportCandidatesReport({ vacancyId, establishmentId, email }) {
  return api.post(
    `v2/recruta/unit/${establishmentId}/position/${vacancyId}/candidates/report/`,
    {
      email,
    }
  );
}

/**
 * @description Lista de benefícios e reasões do recruta
 * @example
 * getVacancySettings()
 */
export const getVacancySettings = () => {
  return api.get(`v2/recruta/unit/settings`);
};

/**
 * @description Cria nova vaga do recruta
 * @param {number} estabPk pk do estabelecimento
 * @param {object} body objeto para criar a vaga
 * @param {number} body.establishment pk do estabelecimento
 * @param {number} body.role pk da role
 * @param {number} body.kind pk to tipo de vaga
 * @param {string|null} body.wage salário a ser pago ou null se for "A combinar"
 * @param {array} body.days_of_work array de dias da escala de trabalho (sunday, saturday, friday, thursday, wednesday, tuesday, monday)
 * @param {string} body.start_hour_of_work horário de ínicio de trabalho - formato HH:MM:SS
 * @param {string} body.end_hour_of_work horário de fim de trabalho - formato HH:MM:SS
 * @param {object} body.reason objeto da reason
 * @param {number} body.reason.reason pk da reason da vaga
 * @param {string} body.description descrição da vaga
 * @param {array} body.benefits array de pk de benefícios
 * @param {number} body.max_worker_radius número do raio de atuação
 *
 * @example
 * createVacancy({
 * estabPk: 4
 * body: {
 *  establishment: 4
 *  role: 2
 *  kind: 5
 *  wage: null
 *  days_of_work: ["sunday"]
 *  start_hour_of_work: "10:00:00"
 *  end_hour_of_work: "15:00:00"
 *  reason: reason: 5
 *  description: "Descrição"
 *  benefits: [1, 2, 3]
 *  max_worker_radius: 50
 * }})
 */
export const createVacancy = ({ estabPk, body }) => {
  return api.post(`v2/recruta/unit/${estabPk}/positions`, body);
};

/**
 * @description Exibe os detalhes de uma vaga do recruta
 * @param {number} unitId - ID do estabelecimento da vaga
 * @param {number} id - ID da vaga
 * @example
 * getVacancyDetails({ unitId: 1, id: 1 })
 */
export function getVacancyDetails({ unitId, id }) {
  return api.get(`v2/recruta/unit/${unitId}/positions/${id}`);
}

/**
 * @description Lista tempo de experiência
 * @example
 * getExperienceTime()
 */
export function getExperienceTime() {
  return api.get("api/experience_times/");
}

/**
 * @description Lista os candidatos inscritos ou selecionados em uma vaga
 * @param {number} unitId - ID do estabelecimento da vaga
 * @param {number} positionId - ID da vaga
 * @param {number?} page @default 1 - Página atual
 * @param {number?} pageSize @default 10 - Quantidade de itens por página
 * @param {object?} filters - Filtros de busca
 * @example
 * getCandidates({ unitId: 1, positionId: 1, page: 1, pageSize: 10, filters: { experience: 1, ageMin: 18, ageMax: 30 } })
 */
export function getCandidates({
  unitId,
  positionId,
  page = 1,
  pageSize = 10,
  selected = false,
  filters = {
    experience: null,
    ageMin: null,
    ageMax: null,
  },
}) {
  let endpoint = `v2/recruta/unit/${unitId}/positions/${positionId}/applicants?page_size=${pageSize}&page=${page}`;
  if (!!filters.experience) {
    endpoint += `&xp=${filters.experience}`;
  }

  if (!!filters.ageMin) {
    endpoint += `&age_min=${filters.ageMin}`;
  }

  if (!!filters.ageMax) {
    endpoint += `&age_max=${filters.ageMax}`;
  }

  if (selected) {
    endpoint += "&selected=true";
  }

  return api.get(endpoint);
}

/**
 * @description Exibe os detalhes de um candidato
 * @param {number} unitId - ID do estabelecimento
 * @param {number} workerId - ID do profissional
 * @example
 * getDetailsCandidate({ unitId: 1, workerId: 1 })
 */
export function getDetailsCandidate({ unitId, workerId }) {
  return api.get(`v2/establishment/${unitId}/worker/${workerId}`);
}

/**
 * @description Exibe os contatos de um candidato
 * @param {number} unitId - ID do estabelecimento
 * @param {number} workerId - ID do profissional
 * @param {number} applicationId - ID da aplicação
 * @example
 * getContactCandidate({ unitId: 1, workerId: 1, applicationId: 1 })
 */
export function getContactCandidate({ unitId, workerId, applicationId }) {
  return api.get(
    `v2/recruta/unit/${unitId}/positions/${workerId}/applicants/${applicationId}/get_contact`
  );
}

/**
 * @description Lista os candidatos que foram desqualificados para uma vaga do recruta
 * @param {number} establishmentId - ID do estabelecimento da vaga
 * @param {number} vacancyId - ID da vaga
 * @param {number} page - Página atual
 * @param {number} pageSize - Quantidade de itens por página
 */
export function getDisqualifiedList({
  establishmentId,
  vacancyId,
  page,
  pageSize,
}) {
  return api.get(
    `v2/recruta/unit/${establishmentId}/positions/${vacancyId}/applicants/list_excluded?page=${page}&page_size=${pageSize}`
  );
}

/**
 * @description Exibe as especializações de um candidato
 * @param {number} workerId - ID do profissional
 * @example
 * getWorkerSpecializations({ workerId: 1 })
 */
export function getWorkerSpecializations({ workerId }) {
  return api.get(`v2/establishment/worker_courses/${workerId}`);
}

/**
 * @description Oculta um candidato inscrito em uma vaga
 * @param {number} unitId - ID do estabelecimento
 * @param {number} workerId - ID do profissional
 * @param {number} applicationId - ID da aplicação
 */
export function hideCandidate({ unitId, workerId, applicationId }) {
  return api.delete(
    `v2/recruta/unit/${unitId}/positions/${workerId}/applicants/${applicationId}/exclude`
  );
}

/**
 * @description Seleciona um candidato para uma vaga
 * @param {number} unitId - ID do estabelecimento
 * @param {number} workerId - ID do profissional
 * @param {number} applicationId - ID da aplicação
 */
export function selectCandidate({ unitId, workerId, applicationId }) {
  return api.patch(
    `v2/recruta/unit/${unitId}/positions/${workerId}/applicants/${applicationId}`
  );
}

/**
 * @description Remove um candidato selecionado de uma vaga
 * @param {number} unitId - ID do estabelecimento
 * @param {number} workerId - ID do profissional
 * @param {number} applicationId - ID da aplicação
 */
export function removeSelectedCandidate({ unitId, workerId, applicationId }) {
  return api.delete(
    `v2/recruta/unit/${unitId}/positions/${workerId}/applicants/${applicationId}`
  );
}

/**
 * @description Favorita ou desfavorita um candidato
 * @param {number} unitId - ID do estabelecimento
 * @param {number} workerId - ID do profissional
 * @param {boolean} isFavorite - Indica se o profissional é favorito
 */
export function favoriteProfessional({ unitId, workerId, isFavorite }) {
  if (isFavorite) {
    return api.delete(`v2/establishment/${unitId}/favorites/${workerId}`);
  }
  return api.post(`v2/establishment/${unitId}/favorites`, {
    worker: workerId,
  });
}

/**
 * @description Busca se existem vagas com a função informada e se existem profissionais no raio informado
 * @param {number} establishmentId - ID do estabelecimento
 * @param {number} roleId - ID da função
 * @param {number} radius - Raio de atuação
 */
export function getVacancyPreview({ establishmentId, roleId, radius }) {
  return api.post(
    `v2/recruta/unit/${establishmentId}/positions/preview`,
    {
      role: roleId,
      max_worker_radius: radius,
    },
    {
      headers: {
        "notifications-front-banned": true,
      },
    }
  );
}

/**
 * @description Desqualifica um candidato em uma vaga do recruta
 * @param {number} establishmentId - ID do estabelecimento da vaga
 * @param {number} vacancyId - ID da vaga
 * @param {number} applicationId - ID da aplicação do candidato
 */
export function disqualifyWorker({
  establishmentId,
  vacancyId,
  applicationId,
}) {
  return api.post(
    `v2/recruta/unit/${establishmentId}/positions/${vacancyId}/applicants/${applicationId}/exclude`
  );
}

/**
 * @description Requalifica um candidato em uma vaga do recruta
 * @param {number} establishmentId - ID do estabelecimento da vaga
 * @param {number} vacancyId - ID da vaga
 * @param {array} applicationIds - Array de ids das aplicações a serem restauradas
 */
export function requalify({ establishmentId, vacancyId, applicationIds }) {
  return api.patch(
    `v2/recruta/unit/${establishmentId}/positions/${vacancyId}/applicants/revert_exclusions`,
    {
      appliances_ids: applicationIds,
    }
  );
}
