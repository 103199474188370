import { Fragment } from "react";
import PropTypes from "prop-types";

import { Heading } from "libs/purple-ui";

export function Title({ children, badges = [], ...rest }) {
  return (
    <Heading size="h4" {...rest}>
      {children}
      {badges?.map((badge, index) => (
        <Fragment key={index}>&ensp;{badge}</Fragment>
      ))}
    </Heading>
  );
}

Title.propTypes = {
  badge: PropTypes.arrayOf(PropTypes.node),
};
