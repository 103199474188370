import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 0.875rem;
  grid-template-columns: ${({ row }) => (row ? "repeat(2, 1fr)" : "1fr")};
  padding-top: 0.5rem;
`;

export const VisibilityControl = styled.p`
  font: 0.875rem/1.098rem "Muli", sans-serif;
  color: ${({ theme }) => theme.colors.black_pure};
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }
`;
