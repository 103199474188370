import mainService from "services/mainService";

/**
 * --------------------------------------------------
 * @param {number} pkBigcompany
 * @param {string} inputUnits
 * @example
 * listUnits({ inputUnits: "unidade", pkBigcompany: "2" })
 */
export function listUnits({ pkBigcompany, inputUnits = "" }) {
  return mainService.get(
    `v2/bigcompany/${pkBigcompany}/units/names_2?establishment_address=true&establishment_active=true&name=${inputUnits}`
  );
}

/**
 * --------------------------------------------------
 * @param {number} offerId
 * @example
 * duplicateCancelledJob({ offerId: 124 })
 */
export function duplicateCancelledJob({ offerId }) {
  return mainService.post(`v2/jobs/offer/${offerId}/duplicate_cancelled_job`);
}

/**
 * --------------------------------------------------
 * @param {number} offerId
 * @example
 * getOfferDetails({ offerId: 124 })
 */
export function getOfferDetails({ offerId }) {
  return mainService.get(`v2/jobs/offer/${offerId}/establishment`);
}
