import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Checkbox } from "libs/purple-ui";
import { Icon } from "@iconify/react";

import * as S from "./styles";

/**
 * ## Parâmetros
 * @param {boolean} disabled - Define se o checkbox está desabilitado.
 * @param {Object[]} options - As opções do grupo de checkbox.
 * @param {string|number} options[].value - O valor da opção.
 * @param {node|string} options[].name - A label da opção.
 * @param {boolean[]} checkedList - Lista de estados dos checkboxes, onde cada estado corresponde à sua opção no array 'options'.
 * @param {function} onChange - O callback chamado quando o estado de qualquer checkbox é alterado.
 * @param {number|null} showMax - Número máximo de opções a serem exibidas, null para exibir todas. Ao definir um número máximo, um toggle aparecerá para ser possível visualizar o restante
 * @param {boolean} includeMarkAll - Se inclui a opção "Todos".
 * @param {boolean} row - Forma de exibição em linha (Padrão coluna)

 * ## Exemplo de uso
 * @example
 * <Checkbox
 *  disabled={false}
 *  options={[
 *    { name: 'Opção 1', value: '1' },
 *    { name: 'Opção 2', value: '2' },
 *    { name: 'Opção 3', value: '3' }
 *  ]}
 *  checkedList={["1", "2", "3"]}
 *  onChange={() => console.log('Alteração no estado dos checkboxes')}
 *  showMax={5}
 *  includeMarkAll={true}
 * />
 * @returns node
 */

export const CheckboxGroup = ({
  disabled = false,
  options = [],
  checkedList = [],
  onChange = () => {},
  showMax = undefined,
  includeMarkAll = true,
  row = false,
}) => {
  const { t } = useTranslation(["components", "common"]);
  const [control, setControl] = useState({
    max: showMax,
    show: false,
  });

  const enableSlice = options?.length + 1 > control?.max;

  const data = [
    ...(!!includeMarkAll
      ? [
          {
            name: t("common:all.m"),
            value: "",
            all: true,
          },
        ]
      : []),
    ...options,
  ]?.slice(0, !!enableSlice && control?.show ? undefined : control?.max);

  const toggleShowMore = () =>
    setControl((prev) => ({ ...prev, show: !prev?.show }));

  const handleClick = (option) => {
    if (option?.all) {
      onChange([]);
    } else {
      const newCheckedList = checkedList?.includes(option?.value)
        ? checkedList?.filter((item) => item !== option?.value)
        : [...checkedList, option?.value];
      onChange(newCheckedList);
    }
  };

  return (
    <S.Container row={row}>
      {data?.map((option) => (
        <Checkbox
          key={option?.value}
          onChange={() => handleClick(option)}
          text={option?.name}
          disabled={disabled}
          checked={
            option?.all
              ? !checkedList?.length
              : checkedList?.includes(option?.value)
          }
        />
      ))}
      {!!enableSlice && !!control?.max && (
        <S.VisibilityControl onClick={toggleShowMore}>
          {t(`filter.checkbox.view_${control?.show ? "less" : "more"}`)}
          <Icon
            style={{ marginLeft: "0.25rem" }}
            icon={
              control?.show
                ? "fluent:chevron-up-16-regular"
                : "fluent:chevron-down-16-regular"
            }
          />
        </S.VisibilityControl>
      )}
    </S.Container>
  );
};
CheckboxGroup.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  checkedList: PropTypes.array,
  onChange: PropTypes.func,
  showMax: PropTypes.number,
  includeMarkAll: PropTypes.bool,
  row: PropTypes.bool,
};
